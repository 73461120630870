<template>
  <div>
    <component
      :is="layout.template"
      v-for="layout in layouts"
      :key="layout.id"
      :layout="layout"
    ></component>
  </div>
</template>

<script>
import Concise from '@/layouts/Concise';
import Lengthy from '@/layouts/Lengthy';
import Hero from '@/layouts/Hero';
import ConciseRight from '@/layouts/ConciseRight';
import ConciseLeft from '@/layouts/ConciseLeft';

export default {
  computed: {
    layouts() {
      return this.$store.getters.getLayouts('About');
    },
  },
  components: {
    Concise,
    Lengthy,
    Hero,
    ConciseRight,
    ConciseLeft,
  },
};
</script>

<style></style>
